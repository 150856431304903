




import Vue from 'vue';
import Component from 'vue-class-component';
import NewCurve from '@/pages/schedules/components/curves/NewCurve.vue';
import { Schedules } from '@/store';

@Component({
  components: {
    NewCurve
  }
})
export default class EditCurve extends Vue{
  @Schedules.State('userCurves') userCurves;

  curve_data = {};

  created(){
    const curve_id = this.$route.params.id;
    this.curve_data = this.userCurves.find((curve) => curve.id === curve_id);
  }
}

